import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore/lite';

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_APP_ID
} = process.env

const firebaseConfig = {
  // apiKey: REACT_APP_FIREBASE_API_KEY,
  apiKey: "AIzaSyCPtP7sdiKPzU2-3cziQnxm-NB1Gm_DfR4",
  authDomain: "wiri-mobile.firebaseapp.com",
  databaseURL: "https://wiri-mobile-default-rtdb.firebaseio.com",
  projectId: "wiri-mobile",
  storageBucket: "wiri-mobile.appspot.com",
  messagingSenderId: "798715829506",
  // appId: REACT_APP_FIREBASE_APP_ID,
  appId: "1:798715829506:web:17cf5dcc17f1ae58b5761b",  
  measurementId: "G-0WY15G7C5C"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
