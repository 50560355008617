import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";

const Specialties = ({ data, cms, country }) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white flex md:justify-center items-center px-5 pb-[50px] pt-[35px] md:pb-0 md:pt-[150px]  md:py-24">
      <div className="lg:mb-6  w-full lg:max-w-[988px]  xl:max-w-[1170px]">
        <div
          className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-2 lg:mb-4 xl:mb-[40px] lg:flex lg:justify-center lg:gap-2"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_11",
              'Los <span class="text-primary">beneficios</span> de una prepaga, sin cuota mensual</div>',
              { context: country }
            ),
          }}
        />
        <div className="flex flex-wrap justify-between sm:justify-start">
          {data.map((card, index) => (
            <Link
              key={`prepaid-${index}`}
              className="mb-[20px] h-[56px] lg:h-[80px!important] border rounded-lg border-txtgray/30 flex items-center gap-x-[5px] lg:gap-x-3 px-[8px] lg:px-4 lg:py-[20px] w-[48%] sm:w-full sm:mr-[20px] max-w-[200px] lg:max-w-[300px] xl:max-w-[352px]"
              to={`${cms.country.slug}/${card.link || "turnos"}`}
            >
              <div className="w-[20px] h-[20px] lg:w-[60px] lg:h-[60px] lg:text-[45px]">
                {card.icon}
              </div>
              <div className="text-secondary font-medium leading-[16px] lg:leading-[36px] text-[15px] lg:text-[20px]">
                {card.title}
              </div>
            </Link>
          ))}
        </div>

        <Link
          to={"/ar/turnos/"}
          className="block text-[14px] lg:text-[20px] text-secondary text-center lg:hidden"
        >
          Ver todos los servicios
        </Link>
      </div>
    </div>
  );
};

export default Specialties;
