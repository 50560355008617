import installments from '../../../assets/installments.png'
import daBox from '../../../assets/daBox.png'
import inter from '../../../assets/inter.png'
import { useTranslation } from 'react-i18next'

const Solutions = ({country}) => {
    const {t} = useTranslation();

    return (
        <div className="flex md:justify-center items-center px-5 pt-[35px] pb-[50px] lg:pt-[129px] lg:pb-[150px]">
        <div className="mb-3 lg:mb-0 w-full lg:max-w-[988px] xl:max-w-[1170px]">
          <div
            className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-[20px] sm:mb-4 lg:mb-8  xl:mb-[69px]"
            dangerouslySetInnerHTML={{ __html: t('home_text_24', 'Una <span class="text-primary">solución</span> que se adapta a todos', { context: country })}}
          />

          <div className="flex flex-col lg:flex-row justify-around">
            <div className="w-full mb-[50px] lg:mb-0 lg:w-[31.5%]">
              <div className="w-full h-[165px] lg:h-auto overflow-hidden mb-[20px] xl:mb-[40px] rounded-b-lg">
                <img className="w-full" src={installments} alt="imagen de wiri sin cuotas" />
              </div>
              <div>
                <div
                  className="text-[15px] lg:text-[24px] font-medium mb-[9px] sm:mb-4"
                  dangerouslySetInnerHTML={{ __html: t('home_text_25', 'Wiri sin cuotas', { context: country })}}
                />

                <div
                  className="text-[15px] lg:text-[22px]  text-primary font-medium mb-[9px] sm:mb-4"
                  dangerouslySetInnerHTML={{ __html: t('home_text_26', '$0/mes', { context: country })}}
                />

                <div
                  className="text-[15px] text-txtgray lg:text-[21px] mb-5 xl:mb-[40px]"
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'home_text_27',
                      'La mejor atención médica para vos y tu familia sin trámites ni cuotas. Disfrutá de turnos y estudios médicos accesibles pagando solo por lo que usás.',
                      { context: country })
                    }}
                  />

                <button
                  data-toggle="categories"
                  className="bg-primary text-[15px] lg:text-[24px] rounded-full w-[145px] lg:w-full px-2 py-[9px] lg:px-6 lg:py-2 text-center text-white font-medium block"
                  dangerouslySetInnerHTML={{ __html: t('home_text_28', 'Reservar turno', { context: country })}}
                />
              </div>
            </div>

            {country === 'ar' && (
              <div className="w-full mb-[50px] lg:mb-0 lg:w-[31.5%]">
                <div className="w-full h-[165px] lg:h-auto overflow-hidden mb-[20px] xl:mb-[40px] rounded-b-lg">
                  <img className="w-full" src={daBox} alt="imagen de la caja promo" />
                </div>
                <div>
                  <div className="text-[15px] lg:text-[24px] font-medium mb-[9px] sm:mb-4">Wiri Plus x La Caja</div>

                  <div className="text-[15px] lg:text-[22px] text-primary font-medium mb-[9px] sm:mb-4">$3500/mes</div>

                  <div className="text-[15px] text-txtgray lg:text-[21px] mb-5 xl:mb-[40px]">
                    Accedé a una cobertura médica desarrollada por Wiri en alianza con La Caja para cubrirte frente a gastos médicos inesperados. ¡Por solo $3500 por mes!
                  </div>

                  <a
                    href="http://wiri.abroker.com.ar/"
                    rel="noreferrer"
                    target="_blank"
                    className="bg-primary text-[15px] lg:text-[24px] rounded-full w-[145px] lg:w-full px-2 py-[9px] lg:px-6 lg:py-2 text-center text-white font-medium block"
                  >
                      Ver más
                  </a>
                </div>
              </div>
            )}

            <div className="w-full lg:w-[31.5%]">
              <div className="w-full h-[165px] lg:h-auto overflow-hidden mb-[20px] xl:mb-[40px] rounded-b-lg">
                <img className="w-full" src={inter} alt="imagen de wiri para empresas" />
              </div>
              <div>
                <div className="text-[15px] lg:text-[24px] font-medium mb-[9px] sm:mb-4">Wiri para empresas</div>

                <div className="text-[15px] lg:text-[22px] text-primary font-medium mb-[9px] sm:mb-4">
                  Cotizá haciendo click <a href="https://empresas.wiri.la/" target="_blank" rel="noopener noreferrer" className="hover:underline">acá</a>
                </div>

                <div className="text-[15px] text-txtgray lg:text-[21px] mb-5 xl:mb-[40px]">
                  Sabemos que la salud de tus empleados es la inversión más rentable. Cuidá de tu equipo con nuestros programas especiales para empresas
                </div>

                <a
                  target="_blank"
                  href="https://empresas.wiri.la/"
                  rel="noreferrer"
                  className="bg-primary text-[15px] lg:text-[24px] rounded-full w-[145px] lg:w-full px-2 py-[9px] lg:px-6 lg:py-2 text-center text-white font-medium block"
                >
                  Ver más
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default Solutions;