import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import uniq from 'lodash/uniq'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from '@reach/router'

import api from '../../services/api'
import Card from './Card'
import useStore from './useStore'
import Header from './Header'
import useCMS from './useCMS'
import Loader from '../../Loader'
import useScroll from '../../hooks/useScroll'
import LandingHeader from '../../shared/components/LandingHeader';

const Results = props => {
  const cms = useCMS()
  const { i18n, t } = useTranslation()
  const location = useLocation()
  const [options, setOptions] = useState()
  const [items, setItems] = useState([])
  const [ready, setReady] = useState()
  const [address, coords] = useStore(state => [state.address, state.coords])
  const isFetchedByScroll = useRef(false)

  const scroll = () => {
    if (query.isFetching || !options || ready) return
    const page = (options.page || 1) + 1
    isFetchedByScroll.current = true
    setOptions({ ...options, page })
  }

  const $bottom = useScroll(scroll);

  const query = useQuery(
    ['availability/resources', options],
    () => api('/availability/resources', options),
    { enabled: !!options?.service && !!options?.coords }
  )

  const facets = useQuery(
    ['availability/facets', options],
    () => api.post('/availability/facets', {
      ...options,
      financier: undefined,
      price:  'all',
      valid:  'all',
      marketplace:  true,
    }),
    { enabled: !!options?.service && !!options?.coords }
  )

  useEffect(() => {
    if (query.isFetched && Array.isArray(query.data)) try {
      setReady(query.data.length < 10)
      isFetchedByScroll.current ? setItems(prevItems => [...prevItems, ...query.data]) : setItems([...query.data])
      window.gtag("event", "results", { value: query.data?.length || 0 });
    } catch {}
  }, [query.data, query.isFetched])

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    const services = cms?.categories?.current?.slug?.split('/').filter(Boolean) || []
    let service = [services[1], services.slice(-1)[0]].filter(Boolean).join('/')

    const options = {
      service,
      financier: params.get('financier') || props.financier?.id || 'wiri',
      distance: cms?.zones?.current?.radio || params.get('distance') || params.get('radio'),
      coords: coords || cms?.zones?.current?.coords,
    }

    try {
      let _coords = [params.get('lat'), params.get('lng')].filter(Boolean)
      if (_coords.length === 2) {
        _coords = _coords.join(',')
      } else {
        _coords = params.get('coords')
      }
      if (_coords) {
        options.coords = _coords
      }
    } catch {}

    isFetchedByScroll.current = false
    if(params.get('checkout')){
      window.localStorage.setItem('checkout', params.get('checkout'))
    }
    setOptions({ ...options })

    
    
  }, [
    cms?.zones?.current?.radio,
    props.financier?.id,
    cms?.categories?.current?.slug,
    location.search,
    coords
  ])

  if (!cms || !options) return <Loader />

  let financiers = Object.keys(facets?.data?.financiers || {}).map(key => key.split('/')[0])
  financiers = uniq(financiers).sort((a, b) => {
    if (a.includes('wiri')) return -1
    if (b.includes('wiri')) return 1
    if (a < b) return -1
    if (a > b) return 1
    return 0
  })

  financiers = financiers.map(id => ({ ...facets?.data?.financiers[id], id }))

  return (
    <div>
      <LandingHeader />
      
      <div className="sm:mx-5">
        <Header
          labels={cms?.categories?.current?.labels || cms?.categories?.current?.label?.split('/')}
          subtitle={address || uniq([cms?.country?.label, cms?.zones?.current?.label].filter(Boolean)).join(' - ')}
          facets={facets}
          financiers={financiers}
        />

        {cms?.categories?.current?.description?.trim()?.length > 5 && (
          <div
            dangerouslySetInnerHTML={{
              __html: cms?.categories?.current?.description
            }}
          />
        )}

        <div className="flex flex-col gap-y-6 mb-6">
          {items.length > 0 ? (
            items.map(item => (
              <Card key={`${item.location?.id || ''}${item.id}`} item={item} filters={options} />
            ))
          ) : query.isFetched && financiers.length > 0 && (
            <div>
              <div className="bg-yellow-100 p-3 text-yellow-600 my-3">
                {t('no_results_message', 'No encontramos resultados. Podes buscar por alguna de las coberturas del listado de abajo')}
              </div>
              <div className="flex flex-inline flex-col gap-y-2">
                {financiers.filter(f => !f.id.includes(options.financier)).map(financier => (
                  <Link
                    key={financier.id}
                    className="p-3 bg-blue-100 text-blue-600 rounded flex-0"
                    to={`${location.pathname}?financier=${financier.id.replace('pms_financiers:', '')}`}
                  >{i18n.financier(financier.id, financier.label)}</Link>
                ))}
              </div>

            </div>
          )}
        </div>
        <div ref={$bottom}>
          {query.isLoading && (
            <Loader className="mt-7 flex flex-col items-center" />
          )}
        </div>

      </div>

    </div>

  )
}


export default Results
