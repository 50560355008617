import img4 from '../../../assets/4.jpeg'
import { useTranslation } from "react-i18next";

const Institutional = ({country}) => {
    const {t} = useTranslation();
    return (
        <div className="bg-white flex md:justify-center items-center px-5 py-9 lg:pb-[150px] lg:pt-[80px] xl:pt-[240px]">
        <div className="flex md:justify-between mb-3 lg:mb-6 xl:mb-0 w-full lg:max-w-[988px]  xl:max-w-[1170px] ">
          <div className="hidden lg:block xl:w-[425px] overflow-hidden rounded-xl mr-9">
            <img className="w-full" src={img4} alt="doctora sonriente con estetoscopio y lapiz en mano" />
          </div>

          <div className="flex flex-col lg:justify-center lg:w-[620px] lg:min-w-[620px] lg:mr-[0px]">
            <div
              className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-2 lg:mb-4"
              dangerouslySetInnerHTML={{ __html: t('home_text_20', 'Convirtiendo la atención médica en un derecho <span class="text-primary">universal</span>', { context: country })}}
            />

            <div
              className="text-txtgray text-[15px] lg:text-[22px] mb-5"
              dangerouslySetInnerHTML={{
                __html: t('home_text_21', 'Wiri es una empresa de tecnología con una misión: que todos podamos disfrutar de una vida saludable sin importar de dónde venimos.', { context: country })
              }}
            />

            <div
              className="text-txtgray text-[15px] lg:text-[22px] mb-8"
              dangerouslySetInnerHTML={{
                __html: t('home_text_22', 'Por eso creamos una plataforma donde podés acceder a servicios médicos de primer nivel accesibles, transparentes y convenientes. Juntos, estamos construyendo un sistema de salud sustentable donde todos hacemos parte. ¿Y vos, te unís a la revolución?', { context: country })
              }}
            />

            <button
              className="bg-primary lg:text-[24px] rounded-full w-[145px] sm:w-[310px] lg:w-[430px] py-2 lg:px-6 lg:py-3 text-white font-medium"
              dangerouslySetInnerHTML={{ __html: t('home_text_23', 'Ver más', { context: country })}}
            />
          </div>
        </div>
      </div>
    )
}

export default Institutional;