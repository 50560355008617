const Buttons = ({ onClick }) => {
  return (
    <div className="hidden lg:block">
      <button
        onClick={onClick}
        className="border rounded-full bg-primary h-[50px] w-[50px] text-[34px] text-white mr-4"
      >
        {"<"}
      </button>

      <button
        onClick={onClick}
        className="border rounded-full bg-primary h-[50px] w-[50px] text-[34px] text-white"
      >
        {">"}
      </button>
    </div>
  );
};

export default Buttons;
