import { useTranslation } from "react-i18next";
import msn from "../../../assets/msn.png";
import wsp from "../../../assets/wsp.png";

const ContactUs = ({ country }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white lg:flex md:justify-center items-center pb-[50px] lg:py-0">
      <div className="bg-primary px-[20px] py-[35px] lg:p-24">
        <div
          className="text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-4 lg:mb-8 text-white"
          dangerouslySetInnerHTML={{
            __html: t("home_text_29", "¿No necesitás ningún turno?", {
              context: country,
            }),
          }}
        />

        <div
          className="text-white text-[15px] lg:text-[22px] mb-4 lg:mb-9"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_30",
              "Dejanos tu mail y recibí descuentos, ofertas exclusivas, información sobre salud y mucho más.",
              { context: country }
            ),
          }}
        />

        <div className="flex items-center bg-white h-[42px] lg:h-[71px] pl-4 rounded-lg">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[18px] h-[18px] sm:w-[20px] sm:h-[20px]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
          </div>
          <input
            className="grow text-[13px] sm:text-[22px] focus:outline-0 focus:shadow-transparent border-none"
            placeholder={t("home_text_31", "email@email.com", {
              context: country,
            })}
            type="email"
            name="email"
            id="email"
          />

          <button className="bg-primary h-full w-[50px] lg:w-[78px] rounded-r-lg border border-l-0 border-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-[18px] h-[18px] sm:w-[20px] sm:h-[20px] text-white m-auto"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="px-[20px] pt-[35px] lg:p-20">
        <div
          className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-4 lg:mb-2"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_32",
              '¿O querés <span class="text-primary">más información</span>?',
              { context: country }
            ),
          }}
        />

        <div
          className="text-txtgray text-[15px] lg:text-[22px] mb-[21px] lg:mb-9"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_33",
              'Contactanos a través de nuestros <br class="hidden 2xl:block" /> canales oficiales para que uno de nuestros Wiriers pueda darte una mano.',
              { context: country }
            ),
          }}
        />

        <div>
          <div className="flex items-center mb-[26px]">
            <div className="mr-3">
              <img src={wsp} alt="logo de whatsapp" />
            </div>

            <a
              href={t(
                "home_text_whatsapp_link",
                "https://wa.me/5491168167431",
                { context: country }
              )}
              className="text-[15px] lg:text-[21px]"
              rel="noopener noreferrer"
            >
              {t("home_text_whatsapp_label", "+ 54 9 11 6816 7431", {
                context: country,
              })}
            </a>
          </div>

          <div className="flex items-center">
            <div className="mr-3">
              <img src={msn} alt="logo de msn" />
            </div>

            <a
              href="https://facebook.com/WiriSalud"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[15px] lg:text-[21px] "
            >
              /WiriSalud
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
