import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import useCMS from '../../Events/Wizard/useCMS'
import OurServices from './components/OurServices'
import UsersCritics from './components/UsersCritics'
import Specialties from './components/Specialties'
import AppointmentSteps from './components/AppointmentSteps'
import Banner from './components/Banner'
import Institutional from './components/Institutional'
import Solutions from './components/Solutions'
import ContactUs from './components/ContactUs'
import Footer from './components/Footer'
import Header from '../../shared/components/LandingHeader'

const {
  REACT_APP_HOME_ENDPOINT = "https://brand.wiri.la/home"
} = process.env


export function Landing() {
  const [step, setStep] = useState(1)
  const [tab, setTab] = useState('on-site')
  // const [countItemsCard, setCountItemsCard] = useState(0)
  const [show, setShow] = useState(false)
  const cms = useCMS()
  const {t} = useTranslation()
  const content = useQuery('home:content', () => fetch(`${REACT_APP_HOME_ENDPOINT}/content.json`).then(res => res.json()))
  const containerSpecialtyCards = useRef(null)
  const containerCriticsCards = useRef(null)
  const countItemsCard = useRef(0)
  const countCriticsCard = useRef(0)
  const country = cms?.country?.id

  if (!country || !content.data) return null

  const cards1 = content.data[country]?.cards1 || content.data?.cards1
  const cards2 = content.data[country]?.cards2 || content.data?.cards2
  const cards3 = content.data[country]?.cards3 || content.data?.cards3

  const backToInit = ({target}) => {
    setTab(target.dataset.tab)
    countItemsCard.current = 0
    containerSpecialtyCards.current.setAttribute('style', 'margin-left: 0')
    containerSpecialtyCards.current.scrollLeft = 0
  }

  const showNav = () => setShow(prev => !prev)

  const moveTo = (direction, container, count) => {
    const elementWidth = container.current.firstChild.firstChild.clientWidth + 22
    direction === 'left'
      ? container.current.setAttribute('style', `margin-left: -${count.current * elementWidth}px`)
      : container.current.setAttribute('style', `margin-left: -${count.current * elementWidth}px`)
  }

  const animationSpecialtyCards = ({target}) => {
    const counter = countItemsCard.current
    const containerWidth = containerSpecialtyCards.current.firstChild.clientWidth
    const elementWidth = containerSpecialtyCards.current.firstChild.firstChild.clientWidth + 22
    if(target.innerText === '>') {
      if(containerWidth <= (countItemsCard.current * elementWidth) + 709) return
      countItemsCard.current = counter + 1
      moveTo('left', containerSpecialtyCards, countItemsCard)
      return
    }
    if(!(countItemsCard.current * elementWidth)) return
    countItemsCard.current = counter >= 0 ? counter - 1 : 0
    moveTo('right', containerSpecialtyCards, countItemsCard)
  }

  const animationCriticsCards = ({target}) => {
    const counter = countCriticsCard.current
    const containerWidth = containerCriticsCards.current.firstChild.clientWidth
    const elementWidth = containerCriticsCards.current.firstChild.firstChild.clientWidth + 22
    if(target.innerText === '>') {
      if(containerWidth <= (countCriticsCard.current * elementWidth) - elementWidth/2 ) return
      countCriticsCard.current = counter + 1
      moveTo('left', containerCriticsCards, countCriticsCard)
      return
    }
    if(!(countCriticsCard.current * elementWidth)) return
    countCriticsCard.current = counter >= 0 ? counter - 1 : 0
    moveTo('right', containerCriticsCards, countCriticsCard)
  }

  return (
    // ${show ? 'h-screen' : ''}
    <div className={`text-black overflow-hidden bg-bgGray`}>
      <div className="bg-white pb-9 lg:pb-20">

        <Header />

        <div className="flex justify-center items-center px-5 lg:px-0">
          <div className="w-full sm:px-5 lg:max-w-[988px]  xl:max-w-[1170px] ">
            <div className="mb-3 lg:mb-6 2xl:mb-8">
              <div className="text-secondary text-[23px] sm:text-[30px] md:text-[36px] lg:text-[48px] xl:text-[58px] font-medium mb-3"
                dangerouslySetInnerHTML={{
                  __html: t('home_text_1', 'Bienvenido a la <span class="text-primary">revolución</span> de la salud', { context: country })
                }}
              />
              <div className="text-txtgray text-[15px] lg:text-[23.5px] xl:text-[28px]"
                dangerouslySetInnerHTML={{
                  __html: t('home_text_2', 'Turnos y estudios con profesionales de primer nivel para esta misma semana y a precios accesibles.', { context: country })
                }}
              />
            </div>

            <div className="bg-bgGray rounded-xl py-3 px-1 lg:py-[16px] 2xl:py-[28px] lg:px-4 max-w-[1388px]">
              <button className="flex justify-between items-center gap-x-3 w-full px-3" data-toggle="categories">
                <div className="text-txtgray text-[14px] lg:text-[20px]  xl:text-[24px] sm:pr-6 truncate"
                  dangerouslySetInnerHTML={{
                    __html: t('home_text_3', 'Especialidad o nombre del profesional', { context: country })
                  }}
                />

                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-primary h-6 w-6 lg:h-10 lg:w-10">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex md:justify-center items-center px-5 pt-[35px] pb-[50px] lg:pt-[85px] lg:pb-[75px]">
        <div className="w-full sm:px-5 lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div className="mb-3 lg:mb-6  w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
            <OurServices
              country={country}
              backToInit={backToInit}
              data={cards1}
              containerRef={containerSpecialtyCards}
              animation={animationSpecialtyCards}
              tab={tab}
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-10"/>

      <div className="flex md:justify-center items-center px-5 pt-[35px] pb-[50px] lg:pt-[85px] lg:pb-[75px]">
        <div className="w-full sm:px-5 lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
          <div className="mb-3 lg:mb-6  w-full lg:max-w-[988px]  xl:max-w-[1170px] 2xl:max-w-[1677px] ">
            <UsersCritics
              country={country}
              containerRef={containerCriticsCards}
              data={cards2}
              animation={animationCriticsCards}
            />
          </div>
        </div>
      </div>


      {(!!cards3?.length) && (
        <Specialties data={cards3} cms={cms} country={country}/>        
      )}

      <AppointmentSteps
        step={step}
        setStep={setStep}
        country={country}
      />

      <Banner />           

      <Institutional
        country={country}
      />

      <Solutions
       country={country}
      />

      <ContactUs
        country={country}
      />

      <Footer
        country={country}
      />
     
    </div>
  )
}
