import { useTranslation } from "react-i18next";
import { Link } from '@reach/router'
import { useQuery } from 'react-query'
import upperFirst from 'lodash/upperFirst'
import { createEvent } from "ics";
import { saveAs } from "file-saver";

import useStore from '../../useStore'
import api from '../../services/api'
import Loader from '../../Loader'
import Whatsapp from './Whatsapp'
import Email from './Email'
import useAppUserEvents from "./useAppUserEvents";
import { useEffect } from "react";

const {
  REACT_APP_API_URL,
} = process.env

function Confirmation (props) {
  const { t, i18n } = useTranslation()
  const landing = useStore(state => state.landing || '')
  const {saveEvent, isAddingEventDoc} = useAppUserEvents()

  const event = useQuery(
    ['events', props.eventID, props.token],
    () => api.get(`/events/${props.eventID}/${props.token}`),
    { enabled: !!(props.eventID && props.token) }
  )

  const downloadIcsAppointment = async (data) => {
    try {
      const day = data?.day?.split("-").map((item) => +item);
      const time = data?.time?.split(":").map((item) => +item);
      const duration = data?.service?.duration || 10;
      const coords = data?.coords;

      const event = {
        start: [...day, ...time],
        duration: { minutes: duration },
        title: `Turno con ${data?.resource.label}`,
        description: `Cita para ${data?.service.label}`,
        location: data?.location.address,
        geo: { lat: coords[0], lon: coords[1] },
      };

      createEvent(event, (error, value) => {
        const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
        saveAs(blob, "cita.ics");
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if(event.data?.id) {
      saveEvent(event.data)
    }
  }, [event.data])

  if (!event.data?.id || isAddingEventDoc) return (
    <Loader className="h-screen flex justify-center items-center" />
  )

  const money = value => {
    const currency = t(`currency_symbol_${event.data.pricing.currency}`, '$')
    const string = value?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    return `${currency}${string}`
  }

  let due = event.data.pricing?.total

  if (event.data.mercadopago?.payment?.transaction_amount) {
    due -= event.data.mercadopago?.payment?.transaction_amount
  }

  let mapLink
  if (event.data?.location?.id && event.data?.coords?.length === 2) {
    mapLink = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(event.data?.location?.address)}&destination_place_id=${event.data?.location?.id}`
  } else if (event.data?.coords?.length === 2) {
    mapLink = `https://www.google.com/maps/search/?api=1&query=${event.data.coords.join(",")}`
  }

  const [startsAt, endsAt] = event.data?.range?.split('-') || []
  let dateLabel
  if (event.data?.range && event.data?.status === 'pending') {
    dateLabel = `${i18n.formatDate(event.data.datetime, 'EEEE dd MMMM')}. ${t('ranges_between', `Entre las ${startsAt} y las ${endsAt}`, { startsAt, endsAt })}`
  } else {
    dateLabel = upperFirst(i18n.formatDate(event.data.datetime, "eeee, d MMMM - H:mm 'hs'"))
  }

  if (event.data.flow === 'whatsapp' && event.data.whatsapp) return  (
    <Whatsapp
      event={event.data}
      dateLabel={dateLabel}
    />
  )

  return (
    <>
      {event.data.flow === 'email' && (
        <Email
          event={event.data}
          dateLabel={dateLabel}
        />
      )}
      <div className="bg-white md:rounded-lg p-4">
        <div className="flex flex-col items-center gap-y-2">
          <div className="border rounded-full flex items-center justify-center h-[50px] w-[50px] mb-2 mt-4">
            {event.data.status === 'cancelled' ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="text-red-600 h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={4}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : event.data.status === 'pending' ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="text-orange-600 h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="text-primary h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={4}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            )}
          </div>
          <div className="font-medium text-[19px] text-secondary/80">
            {t(`status_${event.data.status}`, { context: 'checkout' })}
          </div>
          {!!event.data.consumer?.email && (
            <>
              <div className="text-secondary/80 text-[13px]">
                {t('message_1_checkout', 'Enviamos un mail con los datos de tu turno a')}
              </div>
              <div className="text-secondary text-[13px]">
                {event.data.consumer?.email}
              </div>
            </>
          )}
        </div>
        <div className="-mx-4 px-4 border-t pt-4 mt-4 flex flex-col gap-y-1">
          <div className="font-medium text-secondary">{event.data.resource.label}</div>
          <div className="text-[13px] text-secondary">
            {event.data.location.address}
            {mapLink && (
              <>
                &nbsp;&mdash;&nbsp;
                <a href={mapLink} rel="noreferrer"  target="_blank" className="text-blue-500">
                  {t('directions', 'como llegar')}
                </a>
              </>
            )}
          </div>
          <div className="text-[13px] text-secondary">{t(event.data.service.key, event.data.service.label)}</div>
          <div className="text-[13px] text-secondary">{dateLabel}</div>
        </div>
      </div>

      {event.data.pricing?.total && (
        <div className="bg-white md:rounded-lg mt-4 flex flex-col gap-y-3 py-3">
          {event.data?.mercadopago?.payment?.status === 'approved' && (
            <div className="text-[13px] text-secondary/80 px-4 flex items-center justify-between">
              <div>{t('total_paid', 'Ya abonado')}</div>
              <div>{money(event.data.mercadopago.payment.transaction_amount)}</div>
            </div>
          )}
          {due > 0 && (
            <div className="text-[13px] text-secondary/80 px-4 flex items-center justify-between">
              <div>{t('pay_due_at_location', 'A pagar en el consultorio')}</div>
              <div className="text-red-400">{money(due)}</div>
            </div>
          )}
          <div className="font-medium text-[13px] text-secondary/80 border-t pt-3 px-4 flex items-center justify-between">
            <div>{t('total', 'Total')}</div>
            <div>{money(event.data.pricing.total)}</div>
          </div>
        </div>
      )}

      <div className="bg-white w-full mt-4 p-4">
        <span className="text-[13px] text-secondary/80">Podes descargar la cita para agregar a tu agenda Android/iOS presionando </span>
        <button className="text-base text-blue-500 hover:text-blue-700" onClick={() => downloadIcsAppointment(event.data)}>AQUÍ</button>
      </div>

      <div className="bg-white md:rounded-lg p-4 mt-4 sticky bottom-0" style={{ boxShadow: '-0 -3px 12px rgba(0, 0, 0, 0.05)' }}>
        <div className="text-xs md:rounded-t-lg -m-4 p-4 bg-gray-50 text-secondary/80 mb-4 border-b">
          {event.data.datetime > Date.now() + 48 * 60 * 60 * 1000 ? (
            t('cancel_message_gt_48', 'Por favor, si no podés asistir al turno recordá cancelarlo con al menos 48 horas de antelación. Para el prestador es importante, pero también para otras personas que lo necesitan y podrían tomar tu horario.')
          ) : (
            t('cancel_message_lt_48', 'Por favor, si no podés asistir al turno recordá cancelarlo lo antes posible. Para el prestador es importante, pero también para otras personas que lo necesitan y podrían tomar tu horario.')
          )}
        </div>
        {!landing.includes('checkout/') && (
          <div className="text-center">
            <Link
              className="text-sm text-gray-600 hover:text-blue-800"
              to={landing || '/'}>{t('back_home', 'Volver a la home')}</Link>
          </div>
        )}
      </div>
    </>
  )
}

export default Confirmation
