import wsp from "../../../assets/wsp.png";
import fb from "../../../assets/facebook.png";
import ig from "../../../assets/instagram.png";
import lk from "../../../assets/linkedin.png";
import wspB from "../../../assets/wspB.png";
import fbB from "../../../assets/facebookB.png";
import igB from "../../../assets/instagramB.png";
import lkB from "../../../assets/linkedinB.png";
import logo from "../../../assets/logo.svg";
import { useTranslation } from "react-i18next";
import { Link } from "@reach/router";

const Footer = ({ country }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex md:justify-center items-center px-5 py-[35px] pb-[50px] lg:py-[80px] xl:py-[105px]">
        <div className="w-full lg:max-w-[988px] xl:max-w-[1170px] ">
          <div className="sm:grid sm:grid-cols-3 sm:grid-flow-row">
            <div className="sm:mr-6 sm:mb-0 mb-[50px] md:mr-12">
              <Link to="/" className="mb-[20px] lg:mb-7 xl:mb-9 block">
                <img
                  src={logo}
                  className="w-[160px] min-w-[160px] xl:w-[225px] xl:h-[92px] ml-[-26px]"
                  alt="Wiri Logo"
                />
              </Link>

              <div
                className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-7 xl:mb-9 leading-7 xl:leading-[36px]"
                dangerouslySetInnerHTML={{
                  __html: t(
                    "home_text_34",
                    'La salud que merecés al <br className="sm:hidden" /> precio que necesitás',
                    { context: country }
                  ),
                }}
              />

              <div className="flex items-center">
                <div className="w-[20px] h-[20px] mr-[25px] lg:mr-[18px] xl:mr-[40px]">
                  <a
                    href="https://www.facebook.com/WiriSalud/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={fb}
                      alt="logo de facebook"
                    />
                  </a>
                </div>
                <div className="w-[21px] h-[20px] mr-[35px] lg:mr-[18px] xl:mr-[40px]">
                  <a
                    href="https://instagram.com/wirisalud"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="h-[20px]"
                      src={ig}
                      alt="logo de instagram"
                    />
                  </a>
                </div>
                <div className="w-[20px] h-[20px] mr-[35px] lg:mr-[18px] xl:mr-[40px]">
                  <a
                    href="https://www.linkedin.com/company/wirisalud/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="2xl:hidden"
                      src={lk}
                      alt="logo de linkedin"
                    />
                    <img
                      className="hidden 2xl:block"
                      src={lkB}
                      alt="logo de linkedin"
                    />
                  </a>
                </div>
                <div className="w-[20px] h-[20px] mr-[35px] lg:mr-[18px] xl:mr-[40px]">
                  <a
                    href="https://api.whatsapp.com/send?phone=5491168167431&amp;text=%C2%A1Hola!%20%F0%9F%91%8B%20Quiero%20contactar%20con%20Wiri%20"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="xl:hidden"
                      src={wsp}
                      alt="logo de whatsapp"
                    />
                    <img
                      className="hidden xl:block"
                      src={wspB}
                      alt="logo de whatsapp"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="sm:mb-0 mb-[50px]">
              <div
                className="text-txtgray font-medium text-[15px] lg:text-[22px] mb-[10px] lg:mb-7 xl:mb-9"
                dangerouslySetInnerHTML={{
                  __html: t("home_text_35", "¿Querés ser prestador?", {
                    context: country,
                  }),
                }}
              />

              <div
                className="text-txtgray text-[15px] lg:text-[20px] mb-5 lg:mb-7 xl:mb-10 lg:leading-[32px] xl:leading-[42px]"
                dangerouslySetInnerHTML={{
                  __html: t(
                    "home_text_36",
                    "La red de Wiri está en constante crecimiento, sumando nuevos prestadores que quieren seguir construyendo este sistema de salud sustentable.",
                    { context: country }
                  ),
                }}
              />

              <a
                href="https://prestadores.wiri.la/tarifas"
                target="_blank"
                rel="noopener noreferrer"
                className="text-txtgray w-[145px] text-[15px] lg:text-[20px] rounded-full lg:w-[180px] xl:w-[210px] px-4 py-2 lg:px-6 lg:py-3 text-center font-medium block border border-secondary hover:bg-primary hover:text-white hover:border-primary duration-500"
                dangerouslySetInnerHTML={{
                  __html: t("home_text_37", "Ver más", { context: country }),
                }}
              />
            </div>

            <div className="sm:ml-6 md:ml-20">
              <div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a data-toggle="categories">Reservar un turno</a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ar.wiri.la/about"
                  >
                    Sobre nosotros
                  </a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ar.wiri.la/wiriplus"
                  >
                    Wiri Plus
                  </a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://prestadores.wiri.la/tarifas"
                  >
                    Quiero ser prestador
                  </a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://comunidad.wiri.la/"
                  >
                    Blog
                  </a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ar.wiri.la/ayuda"
                  >
                    Preguntas frecuentes
                  </a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] mb-5 lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://legal.wiri.la"
                  >
                    Términos y condiciones
                  </a>
                </div>
                <div className="text-txtgray text-[15px] lg:text-[22px] lg:mb-3 xl:mb-6 hover:text-primary cursor-pointer">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://api.whatsapp.com/send?phone=5491168167431&amp;text=%C2%A1Hola!%20Quiero%20contactar%20con%20Wiri%20%F0%9F%99%8C"
                  >
                    Ayuda
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-white pt-[15px] pb-[22px] px-[15px] lg:p-7 text-center text-txtgray text-[13px] lg:text-[22px]"
        dangerouslySetInnerHTML={{
          __html: t(
            "home_text_38",
            "Wiri Salud - Todos los derechos reservados © 2022",
            { context: country }
          ),
        }}
      />
    </>
  );
};

export default Footer;
