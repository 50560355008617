import { useState } from "react";
import { db } from "../../firebase";
import {
  query,
  getDocs,
  collection,
  where,
  limit,
  addDoc,
} from "firebase/firestore/lite";

const useAppUserEvents = () => {
  const [isAddingEventDoc, setIsAddingEventDoc] = useState(true);

  const saveEvent = async (event) => {
    try {

      const email = event?.consumer?.email;
      const identification = event?.consumer?.identification;

      const users = collection(db, "users");

      const usersQuery = query(
        users,
        where("identification", "==", identification),
        where("email", "==", email),
        limit(1)
      );

      const usersSnapshot = await getDocs(usersQuery);

      usersSnapshot.forEach(async (user) => {
        const { id: userUUID } = user;
        const userProfiles = collection(db, "users", userUUID, "profiles");

        const profilesQuery = query(
          userProfiles,
          where("identification", "==", identification),
          where("email", "==", email)
        );

        const userProfilesSnapshot = await getDocs(profilesQuery);

        const payload = {
          createdAt: event?.createdAt,
          createdBy: userUUID,
          day: event?.day,
          eventId: event?.id,
          files: [],
          financier: {
            id: event?.financier.key,
            label: event?.financier.label
          },
          location: {
            ...event?.location
          },
          paymentData: {
            mercadopago: event?.pricing?.gateway === 'mercadopago',
            reservation: event?.pricing?.reservation,
            status: event?.status,
            transaction_details: {
              payment_type: event?.pricing?.gateway,
              status: event?.mercadopago?.payment?.status || 'approved',
              total: event?.mercadopago?.payment?.transaction_amount || -1
            }
          },
          resource: {
            ...event?.resource
          },
          service: {
            id: event?.service.key,
            label: event?.service.label
          },
          time: event?.time,
          timestamp: new Date(`${event?.day} ${event?.time}`).getTime(),
          token: event?.token,
          updatedAt: event?.updatedAt,
          updatedBy: userUUID
        }

        userProfilesSnapshot.forEach(async (profile) => {
          const events = collection(
            db,
            "users",
            userUUID,
            "profiles",
            profile.id,
            "events"
          );

          await addDoc(events, {...payload});
        });
      });
    } catch (e) {
      console.log(e);
    } finally {
      setIsAddingEventDoc(false);
    }
  };

  return { saveEvent, isAddingEventDoc };
};

export default useAppUserEvents;
