
import Buttons from "./Buttons";
import { useTranslation } from "react-i18next";

const UsersCritics = ({ country, containerRef, data, animation }) => {
  const { t } = useTranslation();
  return (
    <div className="md:flex md:items-center">
      <div className="md:min-w-[450px] md:w-[450px] md:mr-11">
        <div
          className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-2 lg:mb-4"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_8",
              'Lo que dicen nuestros <span class="text-primary">Wirilovers</span>',
              { context: country }
            ),
          }}
        />
        <div
          className="text-txtgray block text-[15px] lg:text-[22px] mb-3"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_9",
              "Nuestra misión es cambiar la forma en la que millones de personas cuidan su salud ¡Y lo estamos logrando!",
              { context: country }
            ),
          }}
        />
        <button
          data-toggle="categories"
          className="text-left hidden lg:block text-primary lg:text-[22px] hover:underline"
          dangerouslySetInnerHTML={{
            __html: t("home_text_10", "Ver cartilla de medicos", {
              context: country,
            }),
          }}
        />
      </div>
      <div>
        <div className="flex flex-col w-full lg:w-screen overflow-hidden">
          <div>
            <div
              ref={containerRef}
              className="relative lg:w-[46vw] xl:w-[53vw] lg:mb-0 h-[300px] lg:h-[370px!important] xl:h-[450px!important] w-full overflow-x-auto overflow-y-hidden lg:overflow-visible"
            >
              <div className=" grid grid-flow-col auto-cols-max gap-x-[15px] md:gap-x-5 pr-7">
                {data.map((card, index) => (
                  <div
                    key={index}
                    className="flex flex-col justify-between bg-white w-[304px] min-w-[304px] lg:w-[340px!important] xl:w-[420px!important] h-[285px] lg:h-[340px!important] xl:h-[420px!important] xl:min-w-[410px!important] lg:min-w-[340px!important] border rounded-2xl border-txtgray/30 block p-[15px] lg:pl-4 lg:pr-3 lg:py-6"
                  >
                    <div className="">
                      <div className="text-txtgray text-[15px] lg:text-[19px] xl:text-[22px] font-medium">
                        {card.consumer}
                      </div>
                      <div className="my-0 xl:my-3">
                        <div className="flex items-center m-[1px] lg:mt-1 lg:mb-2">
                          <svg
                            className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] text-yellow-400 -ml-[3px]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          <svg
                            className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] text-yellow-400 -ml-[3px]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          <svg
                            className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] text-yellow-400 -ml-[3px]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          <svg
                            className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] text-yellow-400 -ml-[3px]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                          <svg
                            className="w-4 lg:w-5 xl:w-[28px] h-5 xl:h-[26px] text-yellow-400 -ml-[3px]"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="text-txtgray text-[15px] lg:text-[15.8px] xl:text-[19.8px] mb-[35px]">
                        {card.description}
                      </div>
                    </div>

                    <div>
                      <div className="text-[12px] lg:text-[14.5px] xl:text-[17px] text-txtgray">
                        <span className="lg:text-[12px] xl:text-[15px] align-middle leading-none mr-1">
                          🩺
                        </span>
                        Se atendió con
                        <span className="text-primary font-medium">
                          {card.resource}
                        </span>
                      </div>

                      <div className="text-[12px] lg:text-[14.5px] xl:text-[17px] text-txtgray my-1 lg:my-3 xl:my-5">
                        <span className="lg:text-[12px] xl:text-[15px] align-middle leading-none mr-1">
                          📅
                        </span>
                        Reservó con solo
                        <span className="text-primary">
                          {card.anticipation}
                        </span>
                      </div>

                      <div className="text-[12px] lg:text-[14.5px] xl:text-[17px] text-txtgray">
                        <span className="lg:text-[12px] xl:text-[15px] align-middle leading-none mr-1">
                          🙌
                        </span>
                        <span
                          dangerouslySetInnerHTML={{ __html: card.line3 }}
                        ></span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Buttons onClick={animation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersCritics;
