import { Link } from "@reach/router";
import { useTranslation } from "react-i18next";
import Buttons from "./Buttons";

const OurServices = ({
  country,
  backToInit,
  data,
  containerRef,
  animation,
  tab,
}) => {
  const { t } = useTranslation();
  return (
    <div className="md:flex md:items-center">
      <div className="md:min-w-[450px] md:w-[450px] md:mr-11">
        <div
          className="text-secondary text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-2 lg:mb-4"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_4",
              '<span class="text-primary">Redefiniendo</span> la atención médica',
              { context: country }
            ),
          }}
        />

        <div
          className="text-txtgray hidden lg:block text-[15px] lg:text-[22px] mb-2"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_5",
              "Recupera el control de tu salud ¡Nosotros te acompañamos! Agendá cualquiera de nuestros servicios y accedé a la mejor atención médica pagando solo cuando te atendés.",
              { context: country }
            ),
          }}
        />

        <button
          data-toggle="categories"
          className="text-txtgray text-left lg:hidden text-[15px] lg:text-[22px] mb-4 lg:mb-2"
          dangerouslySetInnerHTML={{
            __html: t(
              "home_text_6",
              "Agendá cualquiera de nuestros servicios y accedé a la mejor atención médica pagando solo cuando te atendés.",
              { context: country }
            ),
          }}
        />

        <button
          data-toggle="categories"
          className="text-left hidden lg:block text-primary lg:text-[22px] hover:underline"
          dangerouslySetInnerHTML={{
            __html: t("home_text_7", "Buscar un turno en Wiri", {
              context: country,
            }),
          }}
        />
      </div>

      <div>
        <div className="flex flex-col w-full lg:w-screen overflow-hidden">
          <div
            style={
              window.screen.availWidth < 640
                ? { width: window.screen.availWidth - 38, overflow: "auto" }
                : {}
            }
            className="flex items-center pb-[16px] lg:py-0 xl:py-3 mb-[10px] lg:mb-[14px]"
            data-tabs=""
          >
            <button
              onClick={backToInit}
              data-tab="on-site"
              className="lg:text-[19px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none bg-indigo-100/70 text-primary"
            >
              Presencial
            </button>

            <button
              onClick={backToInit}
              data-tab="clinical-study"
              className="lg:text-[19px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none text-txtgray"
            >
              Estudios
            </button>

            <button
              onClick={backToInit}
              data-tab="remote"
              className="lg:text-[19px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none text-txtgray"
            >
              Telemedicina
            </button>

            <button
              onClick={backToInit}
              data-tab="check"
              className="lg:text-[19px] flex items-center font-medium rounded-2xl px-[18px] py-[7px] leading-none text-txtgray"
            >
              Chequeo
            </button>
          </div>

          <div>
            <div
              ref={containerRef}
              className="relative h-[316px] lg:h-[470px!important] xl:h-[550px!important] overflow-x-auto overflow-y-hidden lg:overflow-visible"
            >
              <div className="grid items-center grid-flow-col auto-cols-max gap-x-5 absolute pr-[24px]">
                {!!data.length &&
                  data.map((card, index) => {
                    return (
                      card.tab === tab && (
                        <Link
                          key={`cards1-${index}`}
                          to={`ar/${card.link}`}
                          data-tab={card.tab}
                          className={`bg-white w-[254px] min-w-[254px] lg:w-[340px!important] h-[300px] lg:h-[430px!important] xl:h-[520px!important] xl:w-[380px!important] xl:min-w-[380px!important] lg:min-w-[340px!important] rounded-2xl shadow-lg ${
                            card.link ? "cursor-pointer" : ""
                          }`}
                        >
                          <div className="w-full h-full flex flex-col justify-between pb-4 lg:pb-6">
                            <div className="w-full h-full">
                              <div
                                className="h-[129px] lg:h-[195px] xl:h-[235px] bg-neutral-100 rounded-t-2xl overflow-hidden -mt-[1px] -ml-[1px] -mr-[1px]"
                                style={{
                                  backgroundImage: `url(${card.image})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center center",
                                }}
                              />
                              <div className="lg:pt-[8px] xl:pt-[20px] p-[14.76px] lg:px-3 overflow-hidden lg:mx-1">
                                <div className="text-secondary font-medium text-[15px] lg:text-[20px] xl:text-[22px] lg:mb-[6px] xl:mb-[12px] truncate">
                                  {card.title}
                                </div>
                                <div className="font-medium text-primary/90 lg:mb-[6px] xl:mb-[12px] text-[15px] lg:text-[20px] truncate mb-1 xl-1">
                                  &nbsp;
                                </div>
                                <div className="text-secondary/90 text-[12.5px] lg:text-[16px] xl:text-[18px]">
                                  {card.description}
                                </div>
                              </div>
                            </div>

                            <button className="w-fit mx-auto text-primary rounded-full px-[20px] py-[6.75px] text-[15px] lg:text-[16px] xl:text-[18px] border-2 border-primary hover:text-white hover:bg-primary">
                              Reservar ahora
                            </button>
                          </div>
                        </Link>
                      )
                    );
                  })}

                <div className="flex flex-col items-center pr-4">
                  <Link
                    className="mb-4 text-white bg-primary text-center block text-[29px] w-[40px] h-[40px] rounded-full"
                    to="/ar/turnos/"
                  >
                    {">"}
                  </Link>

                  <p className="text-[15px] lg:text-[22px] text-secondary font-medium">
                    Ver otros
                  </p>
                </div>
              </div>
            </div>
            
            <Buttons onClick={animation}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
