import img1 from '../../../assets/1.jpeg'
import img2 from '../../../assets/2.jpeg'
import img3 from '../../../assets/3.jpeg'
import { useTranslation } from "react-i18next";

const AppointmentSteps = ({step, setStep, country}) => {
    const {t} = useTranslation()
    return (
        <div className="hidden bg-white lg:flex md:justify-center items-center px-5 py-9 lg:py-24">
        <div className="mb-3 lg:mb-6 w-full lg:max-w-[988px]  xl:max-w-[1170px]">
          <div className="flex flex-row justify-between lg:justify-start w-full px:16px">
            <div
              className="md:hidden text-[19px] font-medium mb-7"
              dangerouslySetInnerHTML={{
                __html: t('home_text_12', 'Simple, conveniente y <span class="text-primary">confiable</span>', { context: country })
              }}
            />

            <div className="lg:w-[400px] lg:min-w-[513px] xl:w-[500px] xl:min-w-[513px] lg:mr-[30px] xl:mr-[50px]">
              {step === 1 ? (
                <img className="" src={img1} alt="" />
              ) : step === 2 ? (
                <img className="" src={img2} alt="" />
              ) : step === 3 && (
                <img className="" src={img3} alt="" />
              )}
            </div>

            <div className="hidden md:flex md:flex-col md:w-[513px] md:min-w-[513px] justify-center">
              <div
                className="lg:text-[28px] xl:text-[32px] font-medium mb-2 lg:mb-4"
                dangerouslySetInnerHTML={{
                  __html: t('home_text_13', 'Simple, conveniente y <span class="text-primary">confiable</span>', { context: country })
                }}
              />

              <div>
                <button className="text-left flex flex-col mb-8" onClick={() => setStep(1)}>
                  <div className="flex">
                    <div
                      className={`cursor-pointer lg:w-[35px] lg:min-w-[35px] lg:h-[35px] mr-5 lg:text-[19px] font-medium border rounded-full
                        border-secondary/70 text-center pt-[3px] ${step === 1 ? 'bg-primary text-white' : 'text-txtgray'}`}
                    >
                      1
                    </div>

                    <div
                      className="cursor-pointer text-[15px] text-secondary xl:text-[24px]"
                      dangerouslySetInnerHTML={{ __html: t('home_text_14', 'Encontrá el servicio que necesites', { context: country })}}
                    />
                  </div>

                  {step === 1 && (
                    <div
                      className="xl:ml-[56.5px] 2xl:ml-[100px] text-txtgray mt-2 leading-7"
                      dangerouslySetInnerHTML={{
                        __html: t('home_text_15', 'En nuestra cartilla podrás encontrar centros, médicos y profesionales matriculados de más de 40 especialidades.', { context: country })
                      }}
                    />
                  )}
                </button>

                <button className="text-left flex flex-col mb-8" onClick={() => setStep(2)}>
                  <div className="flex">
                    <div className={`cursor-pointer lg:w-[35px] lg:min-w-[35px] lg:h-[35px] mr-5 lg:text-[19px] font-medium border rounded-full
                        border-secondary/70 text-center pt-[3px]  ${step === 2 ? 'bg-primary text-white' : 'text-txtgray'}`}
                    >
                      2
                    </div>

                    <div
                      className="cursor-pointer text-[15px] text-secondary xl:text-[24px] 2xl:text-[36px] 2xl:leading-[75px]"
                      dangerouslySetInnerHTML={{ __html: t('home_text_16', 'Seleccioná al profesional', { context: country })}}
                    />
                  </div>

                  {step === 2 && (
                    <div
                      className="xl:ml-[56.5px] 2xl:ml-[100px] text-txtgray mt-2 leading-7"
                      dangerouslySetInnerHTML={{
                        __html: t('home_text_17', 'Elegí al profesional que más te convenga según su ubicación, horarios, precios y recomendaciones de otros pacientes.', { context: country })
                      }}
                    />
                  )}
                </button>

                <button className="text-left flex flex-col mb-8" onClick={() => setStep(3)}>
                  <div className="flex">
                    <div className={`cursor-pointer lg:w-[35px] lg:min-w-[35px] lg:h-[35px] mr-5 lg:text-[19px] font-medium border rounded-full
                        border-secondary/70 text-center pt-[3px] ${step === 3 ? 'bg-primary text-white' : 'text-txtgray'}`}
                    >
                      3
                    </div>

                    <div
                      className="cursor-pointer text-[15px] text-secondary xl:text-[24px]"
                      dangerouslySetInnerHTML={{ __html: t('home_text_18', 'Reservá tu turno y pagá solo por lo que usás', { context: country })}}
                    />
                  </div>

                  {step === 3 && (
                    <div
                      className="xl:ml-[56.5px] 2xl:ml-[100px] text-txtgray mt-2 leading-7"
                      dangerouslySetInnerHTML={{
                        __html: t('home_text_19', 'Reservá y atendete pagando solo por lo que usás, sin trámites, letras chicas ni cuotas mensuales.', { context: country })
                      }}
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default AppointmentSteps;