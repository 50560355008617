import ios from '../../../assets/Button---App-Store.png'
import android from '../../../assets/Button---Google-Play-Store.png'
import bIos from '../../../assets/big-Button---App-Store.png'
import bAndroid from '../../../assets/big-Button---Google-Play-Store.png'
import phones from '../../../assets/phones-img.png'
import bPhones from '../../../assets/big-phones-img.png'

const Banner = () => {
    return (
        <div className="bg-primary">
        <div className="flex flex-col md:flex-row md:justify-center items-center lg:justify-between py-[35px] pb-0 lg:pt-[40px] w-full sm:px-5 lg:max-w-[988px] xl:max-w-[1170px] m-auto">
          <div className="xl:w-[420px] xl:min-w-[420px] flex flex-col lg:flex-row md:px-0 px-[19px] sm:px-0">
            <div>
              <div className="text-[19px] lg:text-[28px] xl:text-[32px] font-medium mb-2 lg:mb-4 text-white">Wiri es aún mejor desde la App</div>

              <div className="text-[15px] lg:text-[22px] text-white mb-[20px]">
                Descarga la app de Wiri y acceder a una experiencia integral del cuidado de tu salud.
              </div>

              <div className="flex justify-between xl:justify-start mb-[15px] mx-2 lg:mx-0 sm:mb-0">
                <a
                  target="_blank" rel="noopener noreferrer" href="https://onelink.to/p2xgje"
                >
                  <img className="md:hidden h-full" src={android} alt="Play Store" />
                  <img className="hidden md:block" src={bAndroid} alt="Play Store" />
                </a>

                <a
                  target="_blank" rel="noopener noreferrer" href="https://onelink.to/p2xgje"
                >
                  <img className="md:hidden h-full" src={ios} alt="App Store" />
                  <img className="hidden md:block" src={bIos} alt="App Store" />
                </a>
              </div>
            </div>
          </div>

          <div>
            <img className="lg:hidden" src={phones} alt="telefonos con ejemplo de las apps instaladas" />
            <img className="hidden lg:block" src={bPhones} alt="telefonos con ejemplo de las apps instaladas" />
          </div>
        </div>
      </div>
    )
}

export default Banner;