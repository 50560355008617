import set from 'lodash/set'
import get from 'lodash/get'
import { useEffect, useState } from 'react'
import { useQueries } from 'react-query'
import { useLocation } from '@reach/router'

const {
  REACT_APP_CMS_URL
} = process.env

const request = sheet =>
  fetch(`${REACT_APP_CMS_URL}/${sheet}`).then(res => res.json())

const Hook = () => {
  const [state, setState] = useState()
  const location = useLocation()

  const [categories, zones, links] = useQueries([
    { queryKey: 'categories', queryFn: () => request('categories') },
    { queryKey: 'zones', queryFn: () => request('zones') },
    { queryKey: 'links', queryFn: () => request('links') }
  ])

  useEffect(() => {
    let state
    let country

    if (categories.isFetched && zones.isFetched && links.isFetched && Array.isArray(links.data) && Array.isArray(categories.data) && Array.isArray(zones.data)) {
      state = {}
      let category = []
      let zone = []
      const parts = location.pathname.split('/')

      for (const part of parts) {
        if (!part) continue
        const zRexep = new RegExp(`^${[...zone, part].filter(Boolean).join('/')}`)
        const cRexep = new RegExp(`^${[...category, part].filter(Boolean).join('/')}`)
        const zCandidate = zones.data.find(row => zRexep.test(row.id))
        const cCandidate = categories.data.find(row => cRexep.test(row.id))
        if (zCandidate) zone.push(part)
        if (cCandidate) category.push(part)
      }

      country = zone[0]
      category = category.join('/')
      zone = zone.join('/')
      state.links = {}
      for (const { key, value } of links.data) {
        state.links[key] = value
      }

      for (const row of zones.data) {
        const ids = row.id.split('/')
        const labels = row.label.split('/')
        const parts = ['zones']
        for (let i = 0; i < ids.length; i += 1) {
          const id = ids[i]
          const label = labels[i]?.trim()
          parts.push('childrens', id)
          const slug = ids.slice(0, i + 1).join('/')
          set(state, `${parts.join('.')}.slug`, '/' + slug)
          set(state, `${parts.join('.')}.id`, id)
          if (label) set(state, `${parts.join('.')}.label`, label)
        }
        const path = row.id.split('/').join('.childrens.')
        if (row.radio) set(state, `zones.childrens.${path}.radio`, row.radio)
        if (row.coords) set(state, `zones.childrens.${path}.coords`, row.coords)
      }

      for (const row of categories.data) {
        if (!row[country]) continue
        const ids = row.id.split('/')
        const labels = row[country].split('/')
        const description = row[`description_${country}`]
        const subtitles = row[`subtitle_${country}`]?.split('/') || []
        const coloredSubtitles = row[`subtitle_${country}_color`]?.split('/') || []
        const tags = row.tags?.split(',')?.filter(Boolean) || []
        const parts = ['categories']

        for (let i = 0; i < ids.length; i++) {
          const id = ids[i]
          const label = labels[i]?.trim()
          const subtitle = subtitles[i]?.trim()
          const coloredSubtitle = coloredSubtitles[i]?.trim()
          parts.push('childrens', id)
          const slug = ids.slice(0, i + 1).join('/')
          set(state, `${parts.join('.')}.id`, id)
          set(state, `${parts.join('.')}.slug`, '/' + slug)
          if (label) set(state, `${parts.join('.')}.label`, label)
          set(state, `${parts.join('.')}.labels`, labels.slice(1).filter(p => p?.trim()))
          if (subtitle) set(state, `${parts.join('.')}.subtitle`, subtitle)
          if (coloredSubtitle) set(state, `${parts.join('.')}.coloredSubtitle`, coloredSubtitle)          
          if (i === 0 && /^turnos\//.test(row.id)) {
            set(state, `${parts.join('.')}.level`, { key: 'specialty', label: 'Especialidad' })
          } else if (i === 1 && /^turnos\//.test(row.id)) {
            set(state, `${parts.join('.')}.level`, { key: 'practice', label: 'Practica' })
          } else if (i > 0) {
            set(state, `${parts.join('.')}.level`, { key: 'subcategory', label: 'Subcategoria' })
          }
          if (i === ids.length - 1 && description) {
            set(state, `${parts.join('.')}.description`, description)
          }
          if (tags.length) set(state, `${parts.join('.')}.tags`, tags)
        }
      }
      state.country = state.zones.childrens[country]

      if (category && state.categories) {
        const key = category.split('/').join('.childrens.')
        state.categories.current = get(state.categories.childrens, key)
      }
      if (zone && state.zones) {
        const key = zone.split('/').join('.childrens.')
        state.zones.current = get(state.zones.childrens, key)
      }
    }
    setState(state)
  }, [
    categories.isFetched,
    zones.isFetched,
    links.isFetched,
    categories.data,
    zones.data,
    links.data,
    location.pathname
  ])

  return state
}

export default Hook
