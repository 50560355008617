import logo from "../../assets/logo.svg";

const LandingHeader = () => {
  return (
    <div className="shadow-md px-5 py-5 mb-9 lg:mb-[72px]">
      <div className="flex items-center justify-between lg:m-auto w-full lg:max-w-[988px]  xl:max-w-[1170px]">
        <div className="-ml-5">
          <img src={logo} width="160" alt="logo de wiri salud" />
        </div>
        <div className="hidden lg:text-[22px] sm:flex sm:items-center sm:divide-x sm:divide-x-[3px] sm:divide-black overflow-hidden h-[25px]">
          <div
            className="cursor-pointer hover:text-primary duration-300 pr-7 md:pr-8 font-medium"
            data-toggle="categories"
          >
            Servicios populares
          </div>
          <div className="cursor-pointer hover:text-primary duration-300 px-7 md:px-8 font-medium">
            <a
              href="https://ar.wiri.la/about"
              target="_blank"
              rel="noopener noreferrer"
            >
              Wiri Salud
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
